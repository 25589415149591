import { Flex, Image, Layout, Menu, type MenuProps, theme } from "antd";

import logo from "../../../assets/logo.svg";
import React, { useEffect, useState } from "react";
import { SettingOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Link, useRouterState } from "@tanstack/react-router";
import { checkUserRoles } from "src/utils/user.ts";
import { GeoPersonaIcon, PlacesIcon } from "src/components/Icons.tsx";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const getMenuItems = (): MenuItem[] => {
  const items: MenuItem[] = [
    getItem("Places", "/dashboard/places", <PlacesIcon />, [
      getItem(
        <Link to={"/dashboard/places/map-view"}>Map View</Link>,
        "/dashboard/places/map-view",
      ),
      getItem(
        <Link to={"/dashboard/places/my-data"}>My Data</Link>,
        "/dashboard/places/my-data",
      ),
    ]),
    getItem("GeoPersona", "/dashboard/geopersona", <GeoPersonaIcon />, [
      getItem(
        <Link to={"/dashboard/geopersona/map-view"}>Map View</Link>,
        "/dashboard/geopersona/map-view",
      ),
      getItem(
        <Link to={"/dashboard/geopersona/segment-list"}>Segment List</Link>,
        "/dashboard/geopersona/segment-list",
      ),
    ]),
  ];

  if (checkUserRoles(["trial"])) {
    items.push(
      getItem(
        <Link to={"/dashboard/integrations"}>Integrations</Link>,
        "/dashboard/integrations",
        <ShareAltOutlined />,
      ),
    );
  }

  if (checkUserRoles(["root", "admin", "user"])) {
    items.push(
      getItem(
        <Link to={"/dashboard/integrations/users"}>Integrations</Link>,
        "/dashboard/integrations/users",
        <ShareAltOutlined />,
      ),
    );
  }

  if (checkUserRoles(["root", "admin", "user"])) {
    items.push(
      getItem(
        <Link to={"/dashboard/settings"}>Settings</Link>,
        "/dashboard/settings",
        <SettingOutlined />,
      ),
    );
  }

  return items;
};

export function LayoutSider() {
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const router = useRouterState();
  const { token } = theme.useToken();

  useEffect(() => {
    const pathname = (router.location.pathname ?? "")
      .split("/")
      .slice(0, -1)
      .join("/");
    setOpenKeys([router.location.pathname, pathname]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <Flex justify="center" align="center">
        <Image
          src={logo}
          preview={false}
          height={32}
          wrapperStyle={{ margin: token.margin }}
        />
      </Flex>
      <Menu
        theme="dark"
        openKeys={openKeys}
        onOpenChange={(keys) => setOpenKeys(keys)}
        selectedKeys={[router.location.pathname]}
        mode="inline"
        items={getMenuItems()}
      />
    </Layout.Sider>
  );
}
